<template>
  <div class="app-wrap">
    <div id="nav" :class="{ 'min-heigth-nav': !etatNavBarSetting }">
      <NavBarSetting :etatNavBarSetting="etatNavBarSetting" />
    </div>
    <div
      class="etat-setting-navbar"
      :class="{ 'left-position-etat-icon': !etatNavBarSetting }"
      @click.prevent.stop="changeStatusNavbarS"
    >
      <v-tooltip bottom color="#311B92">
        <template v-slot:activator="{ on, attrs }">
          <font-awesome-icon
            :icon="
              etatNavBarSetting ? ['fal', 'angle-left'] : ['fal', 'angle-right']
            "
            v-on="on"
            v-bind="attrs"
          />
        </template>
        <span class="pl-3 pr-3">{{
          etatNavBarSetting
            ? 'Fermer menu de paramètres'
            : 'Ouvrir menu de paramètres'
        }}</span>
      </v-tooltip>
    </div>
    <div class="main" :class="{ 'min-heigth-main': !etatNavBarSetting }">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { etatNavBarSetting: true };
  },
  components: {
    NavBarSetting: () => import('./NavBarSetting.vue')
  },
  methods: {
    changeStatusNavbarS() {
      this.etatNavBarSetting = !this.etatNavBarSetting;
    }
  }
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.app-wrap {
  position: relative;
  padding: 6px;
  height: calc(100% - 31px);
  width: 100%;
  margin: 0 auto;
  background-color: #eeeeee;
  #nav::-webkit-scrollbar {
    width: 4px;
    height: 7px;
  }
  #nav::-webkit-scrollbar-track {
    background-color: #fff;
    box-shadow: inset 0 0 5px #eae7f6;
    border-radius: 3px;
  }
  #nav::-webkit-scrollbar-thumb {
    background: #cacae3;
    border-radius: 3px;
  }
  #nav {
    position: absolute;
    width: 265px;
    height: 94%;
    padding: 0px;
    padding-left: 5px;
    overflow-y: auto;
    margin-top: 4px;
    cursor: pointer;
    transition: width 0.2s;
  }
  .min-heigth-nav {
    width: 62px !important;
    transition: width 0.2s;
  }
  .main {
    display: block;
    padding: 10px;
    height: calc(100% - 18px);
    margin-left: 264px;
    overflow-y: hidden;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    box-sizing: border-box;
    z-index: 7;
    margin-top: 3px;
    overflow-x: hidden;
  }
  .min-heigth-main {
    margin-left: 58px !important;
  }
  .etat-setting-navbar {
    border: 1px solid #704ad11c;
    background: rgb(237 234 245);
    width: 14px;
    height: 68px;
    top: 52%;
    position: absolute;
    left: 253px;
    border-radius: 6px 0px 0px 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: rgba(112, 74, 209, 1);
      font-size: 11px;
    }
  }
  .left-position-etat-icon {
    left: 51px !important;
  }
}

@media screen and (max-width: 768px) {
  .app-wrap {
    .header {
      .icon {
        background-color: transparent;
        float: left;
        display: block;
        border: none;
        height: 35px;
        width: 35px;
        color: #2dabe2;
        border-radius: 5px;
        outline: 0;
        &:hover {
          border-radius: 50%;
          background-color: rgba(219, 217, 217, 0.54);
        }
      }
    }
  }
}
</style>
